<template>
  <nav class="slide-in-down">
    <div class="container medium-content py-2">
      <div class="d-flex justify-content-between align-items-center">
        <router-link class="logo" to="/">STEFAN AUVACHE</router-link>
        <div>
          <ul class="list-group list-group-horizontal list-unstyled">
            <li class="mx-2 my-0"><router-link class="blue" to="/articles">Articles</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <div class="nav-buffer"></div>
</template>
<script setup>
</script>