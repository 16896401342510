<template>
  <section class="py-4 bg-dark-blue">
    <div class="container thinnest-content text-center fade-in">
      <div>
        <p class="white mb-0">Subscribe to the <span class="fw-bold fst-italic">Food For Thought</span> email list for weekly articles on practical life lessons from the worlds of technology, business, literature, and music.</p>
        <div>
          <div class="subscribe d-flex flex-column flex-sm-row justify-content-center align-items-center">
            <component is="script" async data-uid="0396ab84d5" src="https://stefan-bradley.ck.page/0396ab84d5/index.js"></component>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>